import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef } from '@angular/core';

import { Observable } from 'rxjs';

import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';
import { ProductComponentService } from './product-component.service'; 

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [ ProductComponentService ],
  animations: [
    trigger('shake', [
      state('open', style({})),
      state('closed', style({})),
      transition('open <=> closed', [
        animate('0.1s', keyframes([
          style({ transform: 'translateX(1px) translateY(1px) rotate(0deg)', }),
          style({ transform: 'translateX(-1px) translateY(-2px) rotate(-1deg)', }),
          style({ transform: 'translateX(-3px) translateY(0px) rotate(1deg)', }),
          style({ transform: 'translateX(3px) translateY(2px) rotate(0deg)', }),
          style({ transform: 'translateX(1px) translateY(-1px) rotate(1deg)', }),
          style({ transform: 'translateX(-1px) translateY(2px) rotate(-1deg)', }),
          style({ transform: 'translateX(-3px) translateY(1px) rotate(0deg)', }),
          style({ transform: 'translateX(3px) translateY(1px) rotate(-1deg)', }),
          style({ transform: 'translateX(-1px) translateY(-1px) rotate(1deg)', }),
          style({ transform: 'translateX(1px) translateY(2px) rotate(0deg)', }),
          style({ transform: 'translateX(1px) translateY(-2px) rotate(-1deg)', }),
        ]))
      ])
    ]),
  ]
})
export class ProductComponent implements OnInit {
  constructor(
    public productComponentService: ProductComponentService,
  ) { }
  @HostBinding("style.--bordercolor") bordercolor;
  @HostBinding("style.--borderwidth") borderwidth;
  @HostBinding("style.--resultcolor") resultcolor;
  @HostBinding("style.--unitinputvaluecolor") unitinputvaluecolor;
  @HostBinding("style.--quantityinputvaluecolor") quantityinputvaluecolor;
  @HostBinding("style.--priceinputvaluecolor") priceinputvaluecolor;
  @HostBinding("style.--priceinputplaceholdercolor") priceinputplaceholdercolor;
  @HostBinding("style.--unitinputplaceholdercolor") unitinputplaceholdercolor;
  @HostBinding("style.--quantityinputplaceholdercolor") quantityinputplaceholdercolor;


  shake = false;

  setOriginalDesign() {
    this.bordercolor ="red";
    this.borderwidth="0px";
    this.resultcolor="black";

    this.restoreUnitInputDesign();
    this.restoreQuantityInputDesign();
    this.restorePriceInputDesign();
  }

  restoreUnitInputDesign() {
    this.unitinputvaluecolor="#EFEFEF";
    this.unitinputplaceholdercolor="#808080";
  }

  restoreQuantityInputDesign() {
    this.quantityinputvaluecolor="#EFEFEF";
    this.quantityinputplaceholdercolor="#4F4F4F";
  }

  restorePriceInputDesign() {
    this.priceinputvaluecolor="#EFEFEF";
    this.priceinputplaceholdercolor="#808080";
  }

  ngOnInit(): void {
    this.setOriginalDesign();
  }

  toggleShake() {
    this.shake = !this.shake;
  }

  // Observing product-component-service
  observer = this.productComponentService.guiWinnerSubject.subscribe(a => this.setWinnerDesign());
  observer2 = this.productComponentService.guiResetSubject.subscribe(a => this.resetDesign());
  observer3 = this.productComponentService.guiEqualSubject.subscribe(a => this.setEqual());
  observer4 = this.productComponentService.guiResultColorSubject.subscribe(resultColor => this.setResultColor(resultColor));
  observer5 = this.productComponentService.guiShakeSubject.subscribe(a => this.toggleShake());
  observer6 = this.productComponentService.guiUnitInputErrorSubject.subscribe(a => this.setUnitInputValueError());
  observer7 = this.productComponentService.guiQuantityInputErrorSubject.subscribe(a => this.setQuantityInputValueError());
  observer8 = this.productComponentService.guiPriceInputErrorSubject.subscribe(a => this.setPriceInputValueError());

  observer9 = this.productComponentService.guiResetUnitInputDesignSubject.subscribe(a => this.restoreUnitInputDesign());
  observer10 = this.productComponentService.guiResetQuantityInputDesignSubject.subscribe(a => this.restoreQuantityInputDesign());
  observer11 = this.productComponentService.guiResetPriceInputDesignSubject.subscribe(a => this.restorePriceInputDesign());

  setWinnerDesign() {
    //console.log("Setting winner");
    this.bordercolor = "green";
    this.borderwidth = "2px";
  }

  resetDesign() {
    //console.log("Resetting design");
    this.setOriginalDesign();
    this.productComponentService.setResultMessage("");
  }

  setEqual() {
    //console.log("Setting equal");
    this.resultcolor = "gray";
    this.productComponentService.setResultMessage("(=)");
  }
  
  setResultColor(color) {
    this.resultcolor = color;
  }

  setUnitInputValueError() {
    this.unitinputvaluecolor = "#ffaaaa";
    this.setUnitInputPlaceholderError();
  }
  
  setQuantityInputValueError() {
    this.quantityinputvaluecolor = "#ffaaaa";
    this.setQuantityInputPlaceholderError();
  }
  
  setPriceInputValueError() {
    this.priceinputvaluecolor = "#ffaaaa";
    this.setPriceInputPlaceholderError();
  }

  setPriceInputPlaceholderError() {
    this.priceinputplaceholdercolor = "red";
  }

  setUnitInputPlaceholderError() {
    this.unitinputplaceholdercolor = "red";
  }

  setQuantityInputPlaceholderError() {
    this.quantityinputplaceholdercolor = "red";
  }
}
