<div class="content">

	<div class="top">
	  <app-header> </app-header>
	  <app-unit-choice></app-unit-choice>
	</div>
  
  <ng-container #vf></ng-container>

</div>
<div class="clear"> </div> 
<app-footer> </app-footer>
