import { Injectable, Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';

import { Observable } from 'rxjs';

import { ChoiceManagerService } from './choice-manager.service';
import { ProductComponent } from './product/product.component';

@Injectable({
  providedIn: 'root'
})
export class ProductManagerService {

  viewContainerRef: ViewContainerRef;
  productList = [];
  removeObservableList = [];
  lastChosenUnitObservableList = [];
  workUnit: string;
  lastUnitChosen: number = 1; // defines last chosen unit by user

  constructor(
    private resolver: ComponentFactoryResolver,
    private choiceManagerService: ChoiceManagerService
  ) {
  }

  setViewContainerRef(vf) {
    this.viewContainerRef = vf;
  }

  choiceObservable = this.choiceManagerService.observable.subscribe(data => this.shareChoice(data));

  shareChoice(data) {
    this.workUnit = data;
    for (let i = 0; i < this.productList.length; i++) {
      this.productList[i].productComponentService.resetDesign();
      this.productList[i].productComponentService.setWorkUnit(this.workUnit);
      this.productList[i].productComponentService.setUnidadeIndex(1);
    }
  }

  addDefaultProduct() {
    let name: string;
    let index: number = this.productList.length + 1;
    name = "Produto " + index.toString();
    const factory = this.resolver.resolveComponentFactory(ProductComponent);
    const products = this.viewContainerRef.createComponent(factory);
    this.productList.push(products.instance);
    // Start listening to removeMe()
    let removeObservable = products.instance.productComponentService.indexSubject.subscribe(data => this.removeProduct(data));
    this.removeObservableList.push(removeObservable);
    // Start listening to removeMe()
    let unitChosenObservable = products.instance.productComponentService.unitChosen.subscribe(data => this.setLastUnitChosen(data));
    this.lastChosenUnitObservableList.push(unitChosenObservable);
    // Products inputs
    products.instance.productComponentService.setName(name);
    products.instance.productComponentService.setProductIndex(index - 1);
    products.instance.productComponentService.setWorkUnit(this.workUnit);
    products.instance.productComponentService.quantityInputValue = 1;
    //products.instance.productComponentService.setPriceInput("0.00");
    products.instance.productComponentService.setUnidadeIndex(this.lastUnitChosen);
  }

  removeProduct(index) {
    if (this.productList.length > 2) {
      this.productList.splice(index,1);
      this.viewContainerRef[index] = null;
      this.viewContainerRef.remove(index);
  
      this.removeObservableList.splice(index,1);
      // Update each product index
      for (let i = 0; i < this.productList.length; i++) {
        this.productList[i].productComponentService.setProductIndex(i);
      }
    }
    else { this.productList[index].toggleShake(); }
  }

  setLastUnitChosen(index) {
    this.lastUnitChosen = index;
  }

  clearProducts() {
    this.clearDesign();
    for (let i = 0; i < this.productList.length; i++) {
      if (!this.productList[i].productComponentService.getIsLockedIndex()) {
        this.productList[i].productComponentService.clearValues();
      }
    }
  }

  clearDesign() {
    for (let i = 0; i < this.productList.length; i++) {
      this.productList[i].productComponentService.resetDesign();
    }
  }

  validateProducts() {
    let bool = true;
    for (let i = 0; i < this.productList.length; i++) {
      bool = this.productList[i].productComponentService.validate();
    }
    return bool;
  }

  compareProducts() {
    this.clearDesign();
    if (!this.validateProducts()) {return}
    let specificPriceList=[];
    let betterIndexes=[];
    let betterPrice;
    let worsePrice;
    let worseIndexes=[];
    let minIndex: number;
    for (let i = 0; i < this.productList.length; i++) {
      specificPriceList.push(this.productList[i].productComponentService.getSpecificPrice());
    }
    betterPrice = Math.min(...specificPriceList);
    console.log("Better price", betterPrice);
    for (let i = 0; i < this.productList.length; i++) {
      if (specificPriceList[i] === betterPrice) {
         betterIndexes.push(i);
      }
    }
    worsePrice = Math.max(...specificPriceList);
    console.log("Worse price", worsePrice);
    for (let i = 0; i < this.productList.length; i++) {
      if (specificPriceList[i] === worsePrice) {
         worseIndexes.push(i);
      }
    }

    if (betterPrice === worsePrice) {
      for (let i = 0; i < this.productList.length; i++) {
        this.productList[i].productComponentService.setEqualDesign();
      }
    }
    else {
      for (let i = 0; i < specificPriceList.length; i++) {
        if (worseIndexes.includes(i)) {
          this.productList[i].productComponentService.setResultMessage("Pior preço");
          this.productList[i].productComponentService.setResultColor("red");
        }
        else {
          var text = this.calculateAdvantageRatio(specificPriceList[i], worsePrice) + "%";
          if (betterIndexes.includes(i)) {
            this.productList[i].productComponentService.setResultMessage(text);
            this.productList[i].productComponentService.setResultColor("green");
            this.productList[i].productComponentService.setWinnerDesign();
          }
          else {
            this.productList[i].productComponentService.setResultMessage(text);
            this.productList[i].productComponentService.setResultColor("gray");
          }
        }
      }
    }
  }

  calculateAdvantageRatio(price, maxprice) {
    var ratio;
    ratio = (price/maxprice) * 100;
    ratio = ratio - 100;
    return ratio.toFixed(2);
  }
}
