import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { ChoiceManagerService } from '../choice-manager.service';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { Pagination, Navigation, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-unit-choice',
  templateUrl: './unit-choice.component.html',
  styleUrls: ['./unit-choice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UnitChoiceComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('pesoLiquido') pesoLiquido;

  config: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 0,
    navigation: false,
    autoHeight: true,
    //loop: true,
    loop: false,
    effect: 'fade',
    loopFillGroupWithBlank: true,
    pagination: { clickable: false },
    scrollbar: { draggable: true },
  };

  ngAfterViewInit(){
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
  onSlideChangeEnd(){
    console.log('end');
    console.log("SWIPER:", this.swiper.swiperRef.realIndex);
    console.log("CHOICE:", this.swiperIndexToChoice(this.swiper.swiperRef.realIndex));
    //this.setUnitChoice(this.swiper.swiperRef.realIndex);
    this.setUnitChoice(this.swiperIndexToChoice(this.swiper.swiperRef.realIndex));
  }

  pesoLiquidoChoice: boolean;
  volumeChoice: boolean;
  unidadeChoice: boolean;

  constructor(private choiceManagerService: ChoiceManagerService) { }

  ngOnInit(): void {
    this.setUnitChoice(this.choiceManagerService.getChoiceIndex());
  }

  setUnitChoice(index) {
    this.choiceManagerService.setChoiceByIndex(index);
    if (index === 0) {
      this.pesoLiquidoChoice = true;
      this.volumeChoice = false;
      this.unidadeChoice = false;
      this.pesoLiquido.select;
      //this.swiper.swiperRef.slideTo(2);
    }
    else if (index === 1) {
      this.pesoLiquidoChoice = false;
      this.volumeChoice = true;
      this.unidadeChoice = false;
      //this.swiper.swiperRef.slideTo(0);
    }
    else {
      this.pesoLiquidoChoice = false;
      this.volumeChoice = false;
      this.unidadeChoice = true;
      //this.swiper.swiperRef.slideTo(1);
    }
  }

  swiperIndexToChoice(index) {
    if (index == 0)
    return 1
    if (index == 1)
    return 2
    if (index == 2)
    return 0
  }
}
