<div class=productContent>
  <div class = "firstRow">
    <div class="unitDiv">
      <input class="unitInput" #unitInput type="number" [value]="productComponentService.getUnitInputValue()" [placeholder]="productComponentService.unitPlaceholder" *ngIf="this.productComponentService.getWorkUnit() ==='pesoLiquido' || this.productComponentService.getWorkUnit() ==='volume'" (click)="productComponentService.unitInputClick()" (blur)="productComponentService.unitInputExit()" (keyup)="productComponentService.setUnitInputValue(unitInput.value)" (focus)="selectAllContent($event)" [(ngModel)]="productComponentService.unitInputValue" (ngModelChange)="productComponentService.resetUnitInputDesign()">
      <!-- Buttons -->
      <div class="btn-group" *ngIf="this.productComponentService.getWorkUnit() ==='pesoLiquido'">
        <ng-container *ngFor="let unidade of productComponentService.getPesoLiquidoUnidades(); let i = index;"> 
          <input class="workUnitButton" type="button" [value]="unidade.unidade" (click)="productComponentService.setUnidadeIndex(i)" [class.selected]="productComponentService.getUnidadeIndex()===i">
        </ng-container>
      </div>
      <div class="btn-group" *ngIf="this.productComponentService.getWorkUnit() === 'volume'">
        <ng-container *ngFor="let unidade of productComponentService.getVolumeUnidades(); let i = index;"> 
          <input class="workUnitButton" type="button" [value]="unidade.unidade" (click)="productComponentService.setUnidadeIndex(i)" [class.selected]="productComponentService.getUnidadeIndex()===i">
        </ng-container>
      </div>
    </div>
      <!-- Quantity -->
    <div class="quantityDiv">
       <input class= "quantityButton" type="button" value="+" (click)="productComponentService.addQuantity()">
       <input class="quantityInput" #quantityInput type="number" [value]="productComponentService.quantityInputValue" (keyup)="productComponentService.setQuantityInputValue(quantityInput.value)" (focus)="selectAllContent($event)" [(ngModel)]="productComponentService.quantityInputValue" (ngModelChange)="productComponentService.resetQuantityInputDesign()">
       <input class= "quantityButton" type="button" value="-" (click)="productComponentService.subtractQuantity()">
    </div>
  </div>
  <div class="secondRow">
    <!-- Price -->
    <div class="priceDiv">
      R$ 
      <input currencyMask type="text" [(ngModel)]="productComponentService.priceInput" (ngModelChange)="productComponentService.resetPriceInputDesign()" [options]="{ prefix: '', thousands: '.', decimal: ',', precision: '2' }" class="priceInput" (focus)="selectAllContent($event)" placeholder="0,00">
    </div>
  </div>
  <div class= result *ngIf="productComponentService.displayResult"> {{ this.productComponentService.getResultMessage() }} </div>
</div>
