import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChoiceManagerService {

  data = ["pesoLiquido", "volume", "unidade"];
  
  choice: string;

  observable = new Subject();

  constructor() {
    this.setChoiceByIndex(1);
  }

  setChoiceByIndex(index) {
    this.choice = this.data[index];
    this.observable.next(this.choice);
  }

  getChoice() {
    return this.choice;
  }

  getChoiceIndex() {
    return this.data.indexOf(this.choice);
  }
    
}
