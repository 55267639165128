<div id=divUntChoice>
<swiper #swiper class="mySwiper"
  [config]="config"
  (swiper)="onSwiper($event)"
  (slideChange)="onSlideChange()"
  (slideChangeTransitionEnd)="onSlideChangeEnd()"
>
  <ng-template swiperSlide>
    <input #pesoLiquido id="pesoLiquidoChoice" type ="image" src="../../assets/pesoliquido.png" (click)="setUnitChoice(0)" [class.selected]="pesoLiquidoChoice" />
  </ng-template>
  <ng-template swiperSlide>
    <input id="volumeChoice" type="image" src="../../assets/volume.png" (click)="setUnitChoice(1)" [class.selected]="volumeChoice" />
  </ng-template>
  <ng-template swiperSlide>
    <input id="unidadeChoice" type="image" src="../../assets/unidade.png" (click)="setUnitChoice(2)" [class.selected]="unidadeChoice" /> 
  </ng-template>
</swiper>
</div>
<!--
<div id=divUntChoice>
  <input id="pesoLiquidoChoice" type ="image" src="../../assets/pesoliquido.png" (click)="setUnitChoice(0)" [class.selected]="pesoLiquidoChoice" />

  <input id="volumeChoice" type="image" src="../../assets/volume.png" (click)="setUnitChoice(1)" [class.selected]="volumeChoice" />

  <input id="unidadeChoice" type="image" src="../../assets/unidade.png" (click)="setUnitChoice(2)" [class.selected]="unidadeChoice" /> 
</div>
-->
