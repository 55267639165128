import { Injectable, Input } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductComponentService {
  private workUnit: string // 
  private productIndex: number; // Index in ProductManagerService  ProducList
  private name: string;
  
  private isLockedOptions = [
    {
      value: 0,
      src: "../../assets/unlock.png"
    },
    {
      value: 1,
      src: "../../assets/lock.png"
    }
  ];

  // Observer pattern subjects
  indexSubject = new Subject(); // Observer
  unitChosen = new Subject(); // So product-manager.service knows last chosen unit

  guiWinnerSubject = new Subject();
  guiResetSubject = new Subject();
  guiEqualSubject = new Subject();
  guiResultColorSubject = new Subject();
  guiShakeSubject = new Subject();

  guiUnitInputErrorSubject = new Subject();
  guiQuantityInputErrorSubject = new Subject();
  guiPriceInputErrorSubject = new Subject();

  guiResetUnitInputDesignSubject = new Subject();
  guiResetQuantityInputDesignSubject = new Subject();
  guiResetPriceInputDesignSubject = new Subject();

  private isLockedIndex = 0;
  private isLocked = this.isLockedOptions[this.isLockedIndex];

  private unidadeIndex: number = 1; // Ex.: 0 = kg/l; 1 = g/ml...

  private pesoLiquidoUnidades = [
    {
      unidade: "kg",
      valor: 1,
    },
    {
      unidade: "g",
      valor: 1/1000,
    },
    {
      unidade: "mg",
      valor: 1/1000000,
    }
  ];

  private volumeUnidades = [
    {
      unidade: "l",
      valor: 1,
    },
    {
      unidade: "ml",
      valor: 1/1000,
    }
  ];

  unitMultiplier: number;
  unitInputValue;
  unitPlaceholder: string;
  quantityInputValue: number;
  priceInput;
  private resultMessage: string = "";
  private resultColor: string = "";
  displayResult: boolean = true;

  constructor(
  ) { }

  getUnidadeIndex() {
    return this.unidadeIndex;
  }

  setUnidadeIndex(index) {
    if (this.isLockedIndex === 0) {
      this.unidadeIndex = index;
      this.unitChosen.next(index); // alerting that this was the chosen unit
      if (this.workUnit === "pesoLiquido") {
        this.unitMultiplier = this.pesoLiquidoUnidades[index].valor;
      }
      else if (this.workUnit === "volume") {
        this.unitMultiplier = this.volumeUnidades[index].valor;
      }
      else {
        this.unitMultiplier = 1;
      }
      //console.log(this.unitMultiplier);
    }
  }
  
  getVolumeUnidades() {
    return this.volumeUnidades;
  }
  
  getPesoLiquidoUnidades() {
    return this.pesoLiquidoUnidades;
  }

  getIsLocked() {
    return this.isLocked;
  }

  getIsLockedIndex() {
    return this.isLockedIndex;
  }

  setLockValue(index) {
    this.isLocked = this.isLockedOptions[index];
    this.isLockedIndex = index;
  }

  changeLockValue() {
    this.isLockedIndex === 0? this.isLockedIndex = 1 : this.isLockedIndex = 0;
    this.setLockValue(this.isLockedIndex);
  }

  getName() {
    return this.name;
  }

  removeMe() {
    if (this.isLockedIndex === 0) {
      this.indexSubject.next(this.productIndex);
    }
  }
  
  getProductIndex() {
    return this.productIndex;
  }

  setName(name) {
    this.name = name;
  }

  setProductIndex(index) {
    this.productIndex = index;
  }

  setWorkUnit(unit) {
    this.workUnit = unit;
    this.setUnitPlaceholder();
  }

  getWorkUnit() {
    return this.workUnit;
  }

  getResultMessage() {
    return this.resultMessage;
  }

  setResultMessage(result: string) {
    this.resultMessage = result;
  }

  addQuantity() {
    this.quantityInputValue = this.quantityInputValue + 1;
    if(Number.isInteger(this.quantityInputValue) && this.quantityInputValue > 0) {
      this.resetQuantityInputDesign();
    }
  }

  subtractQuantity() {
    if (this.quantityInputValue > 1) {
      this.quantityInputValue = this.quantityInputValue - 1;
    }
    if(Number.isInteger(this.quantityInputValue) && this.quantityInputValue > 0) {
      this.resetQuantityInputDesign();
    }
  }

  setQuantityInputValue(value) {
    this.quantityInputValue = parseInt(value);
  }


  setUnitPlaceholder() {
    if (this.workUnit === "pesoLiquido") {
      this.unitPlaceholder = "Peso líquido";
    }
    else if (this.workUnit === "volume") {
      this.unitPlaceholder = "Volume";
    }
    else if (this.workUnit === "unidade") {
      this.unitPlaceholder = "Unidade";
    }
    //console.log(this.unitPlaceholder);
  }

  getUnitPlaceholder() {
    let unitPlaceholder;
    if (this.workUnit === "pesoLiquido") {
      unitPlaceholder = "Peso líquido";
    }
    else if (this.workUnit === "volume") {
      unitPlaceholder = "Volume";
    }
    else if (this.workUnit === "unidade") {
      unitPlaceholder = "Unidade";
    }
    return unitPlaceholder;
  }

  unitInputClick() {
    this.unitPlaceholder = "";
  }

  unitInputExit() {
    if (this.unitInputValue.length === 0 ) {
      this.unitPlaceholder = this.getUnitPlaceholder();
    }
  }

  getUnitInputValue() {
    if (this.unitInputValue === undefined) {
      this.unitInputValue = "";
    }
    return this.unitInputValue;
  }

  getPriceInput() {
    return this.priceInput;
  }

  getPrice() {
    var reg = this.priceInput.value.replace(/[.]/g, "");
    reg = reg.replace(/,/g, ".");
    return reg;
  }

  setPriceInput(value) {
    this.priceInput = value
  }

  setUnitInputValue(value) {
    this.unitInputValue = value;
  }

  clearValues() {
    this.quantityInputValue = 1;
    this.setUnidadeIndex(1);
    this.unitInputValue = '';
    this.unitInputExit();
    this.setPriceInput("0,00");
  }

  getSpecificPrice() {
    let specificPrice: number;
    if (this.workUnit != "unidade") {
      specificPrice = this.priceInput /(this.getUnitInputValue() * this.unitMultiplier * this.quantityInputValue);
    }
    else {
      specificPrice = this.priceInput / this.quantityInputValue;
    }
    //console.log("priceInput: " + this.priceInput);
    //console.log("UnitInputValue: " + this.getUnitInputValue());
    //console.log("UnitMultiplier: " + this.unitMultiplier);
    //console.log("Quantity input value: " + this.quantityInputValue);
    return specificPrice;
  }

  validate() {
    let bool = true;
    if (isNaN(this.priceInput) || this.priceInput == "0,00" || this.priceInput == 0.00 )  {
      this.shakeIt();
      // priceInput style change
      this.guiPriceInputErrorSubject.next();
      bool = false;
    }

    if (this.workUnit !== "unidade" && (isNaN(this.getUnitInputValue()) || this.getUnitInputValue() == "")) {
      this.shakeIt();
      //unitInputValue style change
      this.guiUnitInputErrorSubject.next();
      bool = false;
    }

    if (!Number.isInteger(this.quantityInputValue) || this.quantityInputValue <= 0) {
      this.shakeIt();
      // quantityInputValue style change
      this.guiQuantityInputErrorSubject.next();
      bool = false
    }
    return bool;
  }

  // GUI methods
  shakeIt() {
    this.guiShakeSubject.next();
  }
  setWinnerDesign() {
    this.guiWinnerSubject.next();
  }
  resetDesign() {
    this.guiResetSubject.next();
  }
  setEqualDesign() {
    this.guiEqualSubject.next();
  }
  setResultColor(color) {
    this.resultColor = color
    this.guiResultColorSubject.next(this.resultColor);
  }

  resetUnitInputDesign() {
    this.guiResetUnitInputDesignSubject.next();
  }

  resetQuantityInputDesign() {
    this.guiResetQuantityInputDesignSubject.next();
  }

  resetPriceInputDesign() {
    this.guiResetPriceInputDesignSubject.next();
  }
}
