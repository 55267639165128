import { Component, Inject, ViewChild, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ProductManagerService} from './product-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  title = 'comparator';
  @ViewChild("vf", { read: ViewContainerRef }) vf: ViewContainerRef;

  constructor(
    private productManagerService: ProductManagerService
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.productManagerService.setViewContainerRef(this.vf);
      for (let i = 0; i < 2; i++) {
        this.productManagerService.addDefaultProduct();
      }
    });
  }
}
