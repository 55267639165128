import { Component, OnInit } from '@angular/core';

import { ProductManagerService } from '../product-manager.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( private productManagerService: ProductManagerService) { }

  ngOnInit(): void {
  }

  addDefaultProduct() {
    this.productManagerService.addDefaultProduct();
  };

  clearProducts() {
    this.productManagerService.clearProducts();
  };

  compareProducts() {
    this.productManagerService.compareProducts();
  };
}
